import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import BlogTile from '../Utils/BlogTile/BlogTile'
import './events.scss'
// import event1 from '../../assets/images/events/christmasstory.jpg';
import event1 from '../../assets/images/events/joseph.jpeg';
// import event2 from '../../assets/images/case-study/tr.jpg';
// import event3 from "../../assets/images/case-study/v.jpg"

import Banner from '../Utils/Banner/Banner';
import image from '../../assets/images/banner/mic.jpg';

class Eventx extends Component {


  render() {
    return <div className='events'>

      <Banner image={image} link='Events' />

      {/* <!-- Banner area end--> */}
      <section className="main-container" id="main-container">
        <div className="container">

          <BlogTile image={event1} title={'The Blessings of Joseph'}
            details='Join us for this wonderful 3 days program where we dive deep into the blessings of Joseph'>
            <Link to='/contact-us'>More Info</Link>
          </BlogTile>

          {/* <BlogTile image={lovestory} title={"Love's letters"} details={this.details} >
            <Link to='/contact-us'>More Info</Link>
          </BlogTile> */}
        </div>
        {/* <!-- Container End
        --> */}
      </section>
      <div className="gap-60"></div>

    </div>
  }



  EventCard({ image, title, subTitle }) {
    return (
      <div className="col-lg-4 col-md-12">
        <div className="ts-case-box">
          <div className="ts-case-image-wrapper">
            <img
              src={image}
              className='eventImage'
              alt='event cover'
            />
            <div className="ts-case-content">
              <h3 className="case-title">
                <small>{subTitle}</small>{title}
              </h3>
              {/* <p><a className="link-more" href="#">More</a></p> */}
            </div>
          </div>
          {/* <!-- Case Content end--> */}
        </div>
      </div>
    )
  }

}
export default Eventx

