import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';

export default class Sidebar extends Component {
    render() {
        return (
            <Fragment>
                <div className="bg-white col-xs-12 col-md-4 col-sm-4 rightside-column1" id="rightside-column1">
                    <div className="bg-white col-xs-12 col-md-12 col-sm-12">

                        <div className="ts-text-block mrt-60">
                            <h3 align="center">Related Links</h3>
                            <div className="list-group mylist" id="mylist">

                                <Link to="/about-prophet" className="list-group-item"><i
                                    className="fa fa-arrow-circle-right fa-lg"></i> About the Prophet</Link>

                                <Link to="/branches" className="list-group-item"><i
                                    className="fa fa-arrow-circle-right fa-lg"></i> Our Branches</Link>

                                <Link to="/our-faith" className="list-group-item"><i
                                    className="fa fa-arrow-circle-right fa-lg"></i> Statement Of Faith</Link>

                                <Link to="/our-vision" className="list-group-item"><i
                                    className="fa fa-arrow-circle-right fa-lg"></i> Vision Of GEC </Link>

                                {/* <Link to="pro.html" className="list-group-item"><i
                                                className="fa fa-arrow-circle-right fa-lg"></i> Prophet Daniel O. Badu </Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="ts-text-block mrt-20">
                        <div className="col-lg-12 col-md-12">
                            {/* <iframe
                                            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fweb.facebook.com%2FGodlife-Encounter-Church-227311804465008%2F&tabs=timeline&width=320&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                                            width="340" height="500" style="border:none;overflow:hidden" scrolling="no"
                                            frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe> */}
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}
