import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import image from '../../assets/images/banner/contact-us.png';
import { firestore, timestamp } from '../../config';
import { Patterns } from "../../utils/patterns";
import Banner from "../Utils/Banner/Banner";

import "./Contact.scss";


export default function Contact() {

  const [loading, setLoading] = useState()
  const { register, handleSubmit, formState: { isValid }, reset } = useForm()

  async function sendMessage(event) {

    setLoading(true);
    toast.info('Sending Message')
    await firestore.collection('contact-us').doc().set({
      name: event.name,
      email: event.email,
      message: event.message,
      timestamp: timestamp.now()
    }).then(v => {
      setLoading(false);
      toast.success('Message Sent ,We will get back to you in 3 days');
      reset();
    }).catch(error => {
      setLoading(false)
      toast.error('Failed to send message, Please use any of our phone numbers on our contact-us page');
    });
  }

  return (
    <div className='contact-us'>

      <Banner image={image} link='Contact Us' />

      <section className="main-container" id="main-container">
        <div className="container">

          {/* <!-- Title row end--> */}
          <div className="row">
            <div className="col-lg-4">
              <div className="ts-col-inner">
                <div className="ts-contact-info box-border">
                  <span className="ts-contact-icon float-left">
                    <i className="icon icon-map-marker2"></i>
                  </span>
                  <div className="ts-contact-content">
                    <h3 className="ts-contact-title">Find Us</h3>
                    <p><a href="https://goo.gl/maps/trEvdUdXb3ofpvRu5" target='_blank' rel="noreferrer">Nana Yaa Dei Plaza , Dome St. John's, Accra</a></p>
                  </div>
                  {/* <!-- Contact content end--> */}
                </div>
                {/* <!-- End Contact info 1--> */}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ts-col-inner">
                <div className="ts-contact-info box-border">
                  <span className="ts-contact-icon float-left">
                    <i className="icon icon-phone3"></i>
                  </span>
                  <div className="ts-contact-content">
                    <h3 className="ts-contact-title">Call Us</h3>
                    <p><a href="tel:+233542099707">(+233) 542-099-707</a></p>
                  </div>
                  {/* <!-- Contact content end--> */}
                </div>
                {/* <!-- End Contact info 1--> */}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ts-col-inner">
                <div className="ts-contact-info box-border">
                  <span className="ts-contact-icon float-left">
                    <i className="icon icon-envelope"></i>
                  </span>
                  <div className="ts-contact-content">
                    <h3 className="ts-contact-title">Mail Us</h3>
                    <p><a href="mailto:Godlifeecounterchurch18@gmail.com">Godlifeecounterchurch18@gmail.com</a></p>
                  </div>
                  {/* <!-- Contact content end--> */}
                </div>
                {/* <!-- End Contact info 1--> */}
              </div>
            </div>
          </div>
          {/* <!-- Row End--> */}
        </div>
        {/* <!-- container end--> */}
        <div className="gap-60"></div>
        <div className="ts-form" id="ts-form">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <form
                  className="contact-form"
                  id="contact-form"
                  onSubmit={handleSubmit(sendMessage)}

                >
                  <div className="error-container"></div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          className="form-control form-name"
                          id="name"
                          name="name"
                          {...register('name')}
                          placeholder="Full Name"
                          type="text"
                          required
                        />
                      </div>
                    </div>
                    {/* <!-- Col end--> */}
                    {/* <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            className="form-control form-website"
                            id="url"
                            name="url"
                            placeholder="Website"
                            type="url"
                            required
                          />
                        </div>
                      </div> */}
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input
                          className="form-control form-email"
                          id="email"
                          name="email"
                          {...register('email', { required: true, pattern: Patterns.email })}
                          placeholder="Email"
                          type="email"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <textarea
                          className="form-control form-message required-field"
                          id="message"
                          {...register('message', { required: true })}
                          placeholder="Comments"
                          rows="8"
                        ></textarea>
                      </div>
                    </div>
                    {/* <!-- Col 12 end--> */}
                  </div>
                  {/* <!-- Form row end--> */}
                  <div className="text-right">
                    <button
                      disabled={!isValid || loading}
                      className="btn btn-primary tw-mt-30"
                      type="submit"
                    >
                      Contact Us
                    </button>
                  </div>
                </form>
                {/* <!-- Form end--> */}
              </div>
              <div className="col-lg-6">
                <iframe title='GEC Location' src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3970.496173329467!2d-0.24227788584122634!3d5.641105034357673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf99a7573ad825%3A0xf1659f169f2b90ff!2sGodlife%20Encounter%20Church%20(GEC)!5e0!3m2!1sen!2sgh!4v1608618370731!5m2!1sen!2sgh"} width="600" height="450" frameBorder="0" style={{ border: 0 }} allowFullScreen="" tabIndex="0"></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );

}
