import React, { Component } from 'react'
// import { Link } from 'react-router-dom';
import './Welcome.scss';

export default class Welcome extends Component {
    render() {
        return (

            <section style={{ margin: '0 auto', }} className="bg-overlay ts-chart">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-12">
                            <h2 className="column-title title-white">
                                <span>Beloved</span>Welcome to the Godlife family
                            </h2>
                            <p className="intro-desc">
                                <i>
                                    Godlife Encounter Church seeks to build up an amazing family
                                    of love-motivated believers who are learned in the word of
                                    God and partner with the Holy Sprirt to impact the world
                                    with the knowledge and essence of Christ. We will be ever
                                    glad to have you join us as we pursue God in changing the
                                    world for His glory.
                                 </i>
                            </p>

                        </div>
                        {/* <!-- col end--> */}
                        <div className="col-lg-7 col-md-12">

                        </div>
                        {/* <!-- Col end--> */}
                    </div>

                    {/* <!-- Content row end--> */}
                </div>
                {/* <!-- Container end--> */}
            </section>

        )
    }
}
