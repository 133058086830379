import React, { Component, Fragment } from 'react'
import mainImage from '../../assets/images/banner/forest.jpg';
export default class OurVision extends Component {
    render() {
        return (
            <Fragment>
                {/* <!-- Banner area end--> */}
                <section className="main-container" id="main-container">
                    <div id="ts-gallery-layout" className="ts-gallery-layout">
                        <div className="container">
                            <div className="row text-left">
                                <div className="col-lg-12">
                                    <h2 className="">
                                        {/* <span className="section-title-tagline title-light"></span> */}
                                    </h2>
                                </div>
                            </div>
                            {/* <!-- Row End--> */}
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="carrer-gallery-layout"></div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Container End --> */}
                    </div>
                    {/* <!-- Gallery End --> */}
                </section>
                <div className="ts-text-block mrt-60"></div>
                <section id="career-body" className="career-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="career-body-inner">
                                    <div className="job-post-box">
                                        <div className="ts-text-block mrt-60">
                                            <div className="heading">
                                                <h2 className="content-title border-none">
                                                    <span className="shortheading">OUR VISION</span>
                                                </h2>
                                            </div>
                                        </div>
                                        <div className="ts-text-block mrt-40">
                                            <p>
                                                We aim to help you live an impactful life and become an
                                                ambassador of Heaven. Godlife Encounter Church has a
                                                wonderful focus, the church is Christ-centered and
                                                Christ-focused and It is directed at you and aimed at
                                                empowering you to live an impactful life.
                    </p>
                                            <p>
                                                An impactful life is a life worth living, a life lived in
                                                Christ which transforms your environment. You become a
                                                leader, a role model and heaven’s ambassador in your
                                                society, your life becomes a positive impact on others,
                                                you touch lives and you ignite a fire in them which can be
                                                carried from generation to generation.
                    </p>
                                        </div>
                                        <div className="ts-text-block mrt-60"></div>
                                        {/* <!-- Job Box End --> */}

                                        {/* <!-- Row End --> */}
                                    </div>
                                    {/* <!-- Job Post Box End --> */}

                                    {/* <!-- Job Post Box End --> */}
                                </div>
                                {/* <!-- Career Body Inner End --> */}
                            </div>
                            {/* <!-- Col End --> */}
                            <div className="ts-text-block mrt-60">
                                <div className="col-lg-4">
                                    <img src={mainImage} width="330" height="230" alt='mainImage' />
                                </div>
                            </div>

                            {/* <!-- Col End --> */}
                        </div>

                        {/* <!-- Row End --> */}
                    </div>
                    {/* <!-- Container End --> */}
                </section>
                <div className="gap-40"></div>

            </Fragment>
        )
    }
}
