
import React from 'react'

export default function Hero() {

    function download() {
        window.open('../../assets/images/banner/yearofhisfullness_download.jpg', '_black')
    }

    return (
        <div>
            <div className="carousel slide" id="main-slide" data-ride="carousel">
                {/* <!-- Indicators--> */}
                <ol className="carousel-indicators visible-lg visible-md">
                    <li
                        className="active"
                        data-target="#main-slide"
                        data-slide-to="0"
                    ></li>
                    <li data-target="#main-slide" data-slide-to="1"></li>
                    <li data-target="#main-slide" data-slide-to="2"></li>
                </ol>
                {/* <!-- Indicators end--> */}
                {/* <!-- Carousel inner--> */}
                <div className="carousel-inner">
                    <div className="carousel-item active item0">
                        <div className="container">
                            <div className="slider-content text-left">
                                <div className="col-md-12">
                                <h2 className="slide-title title-light">
                                        Welcome to
                                    </h2>
                                    <h3   className="slide-sub-title">
                                          Godlife Encounter Church
                                    </h3>
                                    {/* <h3 className="slide-sub-title">What you do today</h3> 
                    <p className="">
                      Our Year of His treasures <br />
                     than Godlife Encounter Church does.
                    </p> */}
                                </div>

                            </div>

                        </div>

                    </div>


                    <div className="carousel-item  item1">
                        <div className="container">
                            <div className="slider-content text-left">
                                <div className="col-md-12">
                                    <h2 className="slide-title title-light">
                                        Your future is created by
                                    </h2>
                                    <h3 className="slide-sub-title">What you do today</h3>
                                    <p className="slider-description lead">
                                        Nobody’s more committed to connecting you with God <br />
                                        than Godlife Encounter Church does.
                                    </p>
                                </div>
                                {/* <!-- Col end--> */}
                            </div>
                            {/* <!-- Slider content end--> */}
                        </div>
                        {/* <!-- Container end--> */}
                    </div>
                    {/* <!-- Carousel item 1 end--> */}
                    <div className="carousel-item item2">
                        <div className="container">
                            <div className="slider-content text-left">
                                <div className="col-md-12">
                                    <h3 className="slide-sub-title">
                                        We lead you to Christ <br />
                                        regardless
                                    </h3>
                                </div>
                                {/* <!-- Col end--> */}
                            </div>
                            {/* <!-- Slider content end--> */}
                        </div>
                        {/* <!-- Container end--> */}
                    </div>

                    <div className="carousel-item item3 ">
                        <div className="container">
                            <div className="slider-content text-right">
                                <div className="col-md-12">
                                    {/* <h2 className="slide-title title-light"></h2> */}
                                    <h2 className="slide-sub-title">
                                        Your walk with Christ matters to us
                                    </h2>
                                    {/* <p className="slider-description lead">
                      {" "}
                      <br />
                    </p> */}
                                </div>
                                {/* <!-- Col end--> */}
                            </div>
                            {/* <!-- Slider content end--> */}
                        </div>
                        {/* <!-- Container end--> */}
                    </div>
                    {/* <!-- Carousel item 1 end--> */}
                </div>
                {/* <!-- Carousel inner end--> */}
                {/* <!-- Controllers--> */}
                <a
                    className="left carousel-control carousel-control-prev"
                    href="#main-slide"
                    data-slide="prev"
                >
                    <span>
                        <i className="fa fa-angle-left"></i>
                    </span>
                </a>

                <a
                    className="right carousel-control carousel-control-next"
                    href="#main-slide"
                    data-slide="next"
                >
                    <span>
                        <i className="fa fa-angle-right"></i>
                    </span>
                </a>
            </div>
        </div>
    )
}
