import React, { Component, Fragment } from 'react'
import Sidebar from '../Utils/Sidebar/Sidebar';
import image from '../../assets/images/banner/grains.jpg'

export default class OurFaith extends Component {
    render() {
        return (
            <Fragment>
                <section className="main-container" id="main-container">
                    <div id="ts-gallery-layout" className="ts-gallery-layout">
                        <div className="container">
                            <div className="row text-left">
                                <div className="col-lg-12">
                                    <h2 className="">
                                        <span className="section-title-tagline title-light"></span>
                                    </h2>
                                </div>
                            </div>
                            {/* <!-- Row End--> */}
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="carrer-gallery-layout"></div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Container End --> */}
                    </div>
                    {/* <!-- Gallery End --> */}
                </section>

                <div className="ts-text-block mrt-60"></div>

                <section id="career-body" className="career-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="ts-text-block mrt-60">
                                    <img src={image} width="100%" alt='cover' height="20%" />
                                    <div className="career-body-inner">
                                        <div className="job-post-box">
                                            <div className="ts-text-block mrt-20">
                                                <div className="heading">
                                                    <h2 className="content-title border-none">
                                                        <span className="shortheading"></span>Our Statement Of Faith
                                                    </h2>
                                                </div>
                                            </div>
                                            <div className="ts-text-block mrt-20">
                                                <p>
                                                    <strong>THE BIBLE</strong><br />
                                                    We believe in the divine inspiration and authority of
                                                    the Holy Scriptures, that the Bible is infallible in its
                                                    declaration, final in its authority, comprehensive and
                                                    all sufficient in its provisions.
                                                </p>
                                                <p>
                                                    <strong>THE ONE TRUE GOD</strong><br />
                                                    We believe in the existence of the One True God, Elohim,
                                                    Maker of the whole universe; indefinable but revealed as
                                                    Triune God – the Father, the Son and the Holy Spirit one
                                                    in nature, essence and attributes – Omnipotent;
                                                    Omnipresent; Omniscient etc.
                                                </p>
                                                <p>
                                                    <strong>MAN'S DEPRAVED NATURE</strong><br />
                                                    We believe that “all men have sinned and come short of
                                                    the glory of God”, and are subject to eternal
                                                    punishment, and need Repentance and Regeneration.
                                                </p>
                                                <p>
                                                    <strong>THE SAVIOUR</strong><br />
                                                    We believe man’s need of a Saviour has been met in the
                                                    person of Jesus Christ, because of His Deity, Virgin
                                                    Birth, Sinless Life, Atoning death, Resurrection and
                                                    Ascension, His Abiding Intercession and His Second
                                                    Coming to judge the living and the dead.
                                                </p>
                                                <p>
                                                    <strong
                                                    >REPENTANCE, JUSTIFICATION AND SANCTIFICATION</strong
                                                    ><br />
                                                    We believe all men have to repent and confess their sins
                                                    before God, and believe in the vicarious death of Jesus
                                                    Christ before they can be justified before God. We
                                                    believe in the sanctification of the believer through
                                                    the working of the Holy Spirit and God’s gift of eternal
                                                    life to the believer.
                                                </p>
                                                <p>
                                                    <strong
                                                    >THE SACRAMENTS OF BAPTISM AND THE LORD’S SUPPER OR
                                                        COMMUNION</strong
                                                    ><br />
                                                    We believe in the sacrament of Baptism by immersion as a
                                                    testimony of a convert who has attained a responsible
                                                    age. Infants and children are not baptized, but are
                                                    dedicated to the Lord. We believe in the sacrament of
                                                    the Lord’s Supper or Holy Communion, which should be
                                                    partaken by all members who are in full fellowship
                                                </p>
                                                <p>
                                                    <strong>DIVINE HEALING</strong><br />
                                                    We believe that the healing of sicknesses and diseases
                                                    is provided for God’s people in the atonement. However,
                                                    the Church is not opposed to medication by qualified
                                                    medical practitioners.
                                                </p>
                                                <p>
                                                    <strong>TITHES AND OFFERING</strong><br />
                                                    We believe in tithing and in the giving of freewill
                                                    offerings towards the cause of carrying forward the
                                                    Kingdom of God. We believe that God blesses a cheerful
                                                    giver.
                                                </p>
                                                <p>
                                                    <strong>THE SECOND COMING AND THE NEXT LIFE</strong
                                                    ><br />
                                                    We believe in the second coming of Christ and the
                                                    resurrection of the dead, both the saved and the
                                                    unsaved. They that are saved to the resurrection of life
                                                    and the unsaved to the resurrection of damnation.
                                                </p>
                                            </div>
                                            <div className="ts-text-block mrt-60"></div>
                                            {/* <!-- Job Box End --> */}

                                            {/* <!-- Row End --> */}
                                        </div>
                                        {/* <!-- Job Post Box End --> */}

                                        {/* <!-- Job Post Box End --> */}
                                    </div>
                                    {/* <!-- Career Body Inner End --> */}
                                </div>
                            </div>
                            {/* <!-- Col End --> */}
                            <Sidebar />
                            {/* <!-- Col End --> */}
                        </div>

                        {/* <!-- Row End --> */}
                    </div>
                    {/* <!-- Container End --> */}
                </section>
                <div className="gap-40"></div>
            </Fragment>
        )
    }
}
