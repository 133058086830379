import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './intro.scss';
import image from '../../../assets/images/tabs/sunday.jpg'


export default class Intro extends Component {
    render() {
        return (<div className='intro'>
            <section className="ts-intro" id="ts-intro">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 col-sm-12">
                            <h1 className="column-title">

                                About Godlife Encounter Church
                                <p className="mello-7"> <small>...</small> Living an impactful life</p>
                            </h1>
                            <p className="intro-desc">
                                Godlife Encounter Church has a wonderful focus which is directed at you. We are
                                Christ-centered and Christ-focused. We are aimed at empowering you to live an impactful
                                life. An impactful life is a life worth living, a life in Christ that transforms people and the environment.
                                You become a leader, a role model and Heaven's ambassador in your society. Your life becomes
                                a positive impact on others. You touch lives, igniting fire in them which can be carried from
                                generation to generation.
                                 </p>
                            <div className="gap-40"></div>
                            <Link className='btn btn-outline' to='/about'> Learn More </Link>
                            <div className="gap-40"></div>
                        </div>

                        <div className="col-lg-5 col-md-6 col-sm-12">
                            <div className="intro-image-box">
                                <img className="img-fluid" src={image} alt="" />
                                {/* <div className=""> </div> */}
                            </div>
                        </div>


                    </div> {
                        /*
                        <!-- Content row 1 end--> */
                    }

                </div> {
                    /*
                    <!-- Container end--> */
                }

            </section>
        </div>)
    }
}