import React, { Component } from 'react'
import sunday from '../../../assets/images/tabs/bible-studies.jpg';
// import tuesday from '../../../assets/images/tabs/bible-studies.jpg';
import thursday from '../../../assets/images/tabs/thursday.jpg';
// import Banner from '../../Utils/Banner/Banner'
import { Link } from 'react-router-dom';
import './Services.scss';


export default class Services extends Component {
    render() {
        return (<div className='Services'>
            <section className="ts-services solid-bg" id="ts-services">
                <div className="container">
                    <div className="row text-left">
                        <div className="col-lg-12">
                            <h2 className="section-title"> Church Services <span
                                className="section-title-tagline title-light"></span> </h2>
                        </div>
                    </div> {
                        /*
                        <!-- Title row end--> */
                    }

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="featured-tab">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item"> <a className="nav-link active animated fadeIn" href="#tab_one"
                                        data-toggle="tab"> <span className="tab-head"> <span></span> <span
                                            className="tab-text-title"> The Hidden Manna </span> </span> </a> </li> {
                                        /*
                                        <!-- list 1 end--> */
                                    }

                                    {/* <li className="nav-item"> <a className="nav-link animated fadeIn" href="#tab_two"
                                        data-toggle="tab"> <span className="tab-head"> <span></span> <span
                                            className="tab-text-title"> Tuesday Services </span> </span> </a> </li> */}
                                    {
                                        /*
                                        <!-- list 2 end--> */
                                    }
                                    <li className="nav-item"> <a className="nav-link animated fadeIn" href="#tab_three"
                                        data-toggle="tab"> <span className="tab-head"> <span></span> <span
                                            className="tab-text-title"> Prophetic Aura </span> </span> </a> </li> {
                                        /*
                                        <!-- list 3 end--> */
                                    }
                                    {/* 
                                    <li className="nav-item"> <a className="nav-link animated fadeIn" href="#tab_four"
                                        data-toggle="tab"> <span className="tab-head"> <span></span> <span
                                            className="tab-text-title"> Watch-Night Services </span> </span> </a> </li> */}



                                </ul>
                                {
                                    /*
                                    <!-- Nav-tabs end--> */
                                }

                                <div className="tab-content align-ites-center">
                                    <div className="tab-pane active animated fadeInRight" id="tab_one">
                                        <div className="row">
                                            {/* <div className="col-12"> */}
                                            {/* <Banner image={sunday} link='' >
                                                    <div className="col-lg-8">
                                                        <div className="tab-content-info">
                                                            <h3 className="tab-content-title"> Sunday Service </h3>
                                                            <p> Our power-packed Sunday Services are loaded with an abundant supply from
                                                            the presence of the Lord. In-depth teaching of the word of God alongside
                                                            practical illustrations to equip believers with the needed truth and
                                                            experience to enhance kingdom living. The anointing that breaks every
                                                            yoke is always present to deal with divers kinds of demonic bondages on
                                                            the lives of individuals and bring them to enjoying their full
                                                            inheritance in Christ.
                                                     </p>

                                                        </div>
                                                    </div>
                                                </Banner> </div> */}

                                            <div className="col-lg-4 align-self-center">
                                                <div className="bg-contain-verticle" style={{ backgroundImage: { sunday } }}>
                                                    <img className="img-center img-fluid" src={sunday} height="300" alt="" />
                                                </div>
                                            </div>

                                            <div className="col-lg-8">
                                                <div className="tab-content-info">
                                                    <h3 className="tab-content-title"> Sunday Service </h3>
                                                    <p> Our power-packed Sunday Services are loaded with an abundant supply from
                                                        the presence of the Lord. In-depth teaching of the word of God alongside
                                                        practical illustrations to equip believers with the needed truth and
                                                        experience to enhance kingdom living. The anointing that breaks every
                                                        yoke is always present to deal with diverse kinds of demonic bondages on
                                                        the lives of individuals and bring them to enjoying their full
                                                        inheritance in Christ.
                                                    </p>

                                                </div>
                                            </div>

                                        </div>


                                    </div> {
                                        /*
                                        <!-- Tab pane 1 end--> */
                                    }


                                    {/* <div className="tab-pane animated fadeInRight" id="tab_two">
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <div className="tab-content-info">
                                                    <h3 className="tab-content-title"> Bible Studies </h3>
                                                    <p>
                                                        Having an accurate understanding of scriptures is essential to our growth
                                                        in Christ. The man of God, Prophet Daniel by the anointing of the Spirit
                                                        teaches the word of God in a simple yet profound way. Join us, this
                                                        and every Tuesday for an amazing time in the presence of God as we explore
                                                        the truth in the bible.
                                                    </p>

                                                </div> 
                                            </div> 
                                               
                                            <div className="col-lg-4 align-self-center">
                                                <div className="bg-contain-verticle align-self-center" style={{
                                                    backgroundImage: "images/tabs/.png"
                                                }}> <img className="img-center img-fluid" src={tuesday} alt="" />
                                                </div>
                                            </div>

                                        </div> 

                                    </div>  */}

                                    {

                                        <div className="tab-pane animated fadeInRight" id="tab_three">
                                            <div className="row">
                                                <div className="col-lg-4 align-self-center">
                                                    <div className="bg-contain-verticle align-self-center" style={{
                                                        backgroundImage: "images/tabs/.png"
                                                    }}> <img className="img-center img-fluid" src={thursday} alt="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-8">
                                                    <div className="tab-content-info">
                                                        <h3 className="tab-content-title"> Mid-Week Service </h3>
                                                        <p> Our Lord Jesus Christ was a man of prayer. Therefore, it is important to
                                                            build a robust prayer life. Our mid-week services are times of fervent
                                                            prayers unto the Almighty God. They are laced with various
                                                            demonstrations of the Spirit and power such as the prophetic, ministrations,
                                                            healings, miracles and experiencing the manifest presence of the Lord.
                                                            You will be blessed to join us for a supernatural encounter with Jesus.
                                                        </p> {
                                                            /* <a className="btn-light" href="#">
                
                                                            </a> */
                                                        }

                                                    </div> {
                                                        /*
                                                        <!-- Tab content info end--> */
                                                    }

                                                </div>




                                            </div> {
                                                /*
                                                <!-- Row end--> */
                                            }

                                        </div>

                                        /*
                                         <!-- Tab pane 2 end--> */
                                    }
                                    {/* 
                                    <div className="tab-pane animated fadeInRight" id="tab_four">
                                        <div className="row">


                                            <div className="col-lg-8">
                                                <div className="tab-content-info">
                                                    <h3 className="tab-content-title"> Every last Friday Of The Month </h3>
                                                    <p> Night times were not times of sleep for our Lord. They were times of
                                                    intimacy and fellowship with the Father. You can only have victory in
                                                    the day when you learn how to master the night seasons. Our watch-night
                                                    services provide an atmosphere of experiencing the glory of God. These
                                                    services come with a spiritual buffet of the word of God,
                                                    prayers, healings, miracles and amazing prophetic ministrations.
                                                Supernatural Friday is a day you cannot afford to miss. </p>  

                                                </div>  

                                            </div>

                                            <div className="col-lg-4 align-self-center">
                                                <div className="bg-contain-verticle" >
                                                    <img className="img-center img-fluid" src={watchNight} alt="" />
                                                </div>
                                            </div>

                                        </div>  

                                    </div>   */}


                                </div>

                                <div className="gap-40"></div>
                                <div className="text-center"> <Link className="btn btn-primary" to="/branches"> Locate Us </Link>
                                </div>
                            </div> {
                                /*
                                <!-- Schedule tab end--> */
                            }

                        </div> {
                            /*
                            <!-- Col end--> */
                        }

                    </div> {
                        /*
                        <!-- Content row end--> */
                    }

                </div> {
                    /*
                    <!-- Container end--> */
                }

            </section>
        </div>)
    }
}