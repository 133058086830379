import React, { Component } from 'react'
import PropTypes from 'prop-types';
import './BlogTile.scss';

export default class BlogTile extends Component {
    render() {
        let image = this.props.image;
        let title = this.props.title;
        let details = this.props.details;
        return (
            <div className='blog-tile animated fade-in-left'>
                <div className="row">
                    <div className="col-lg-4 align-self-center">
                        <div className="bg-contain-verticle" style={{ backgroundImage: { image } }}>
                            <img className="img-center img-fluid" src={image} height={300} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="content-info">
                            <h3 className="content-title"> {title} </h3>
                            <p> {details} </p>
                            {
                                this.props.children
                            }

                        </div>

                    </div>

                </div>

            </div>
        )
    }
}

BlogTile.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    details: PropTypes.string,
}