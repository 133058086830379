import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { firestore, timestamp } from '../../../config';
import "../../../css/owl.carousel.min.css";
import "../../../css/owl.theme.default.min.css";

export default function Quote() {

    const { register, handleSubmit, formState } = useForm();

    const [loading, setLoading] = useState(false);

    async function post(event) {
        console.log(event)

        try {
            setLoading(true);
            toast.info('Sending Prayer Request',)
            await firestore.collection('Prayers').add({
                name: event.name,
                email: event.email,
                subject: event.subject,
                message: event.message,
                timestamp: timestamp.now(),
            }).then(v => {
                toast.success("Prayer Request Sent; W'll get back to you in within 3 days")
            });
            setLoading(false)
        } catch (error) {
            setLoading(false);
            console.error(error);
            toast('Failed to send request, Try using the available methods on our contact-us page ');
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <section
                className="quote-area bg-overlay overlay-color"
                id="quote-area"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 qutoe-form-inner-left">
                            <div className="quote_form">
                                <h2 className="column-title title-white">
                                    <span>We are always ready</span> Prayer Request
                                </h2>
                                <form
                                    className="contact-form"
                                    id="contact-form"
                                    onSubmit={handleSubmit(post)}
                                >
                                    <div className="error-container"></div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input
                                                    className="form-control form-name"
                                                    id="name"
                                                    name="name"
                                                    {...register('name')}
                                                    placeholder="Full Name"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input
                                                    className="form-control form-email"
                                                    type="email"
                                                    id="email"
                                                    {...register('email')}
                                                    name="email"
                                                    placeholder="Email Address"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Row 1 end--> */}
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <input
                                                    className="form-control form-subject"
                                                    id="subject"
                                                    name="subject"
                                                    type="text"
                                                    {...register('subject')}
                                                    // onChange={(e) => setSubject(e.target.value)}
                                                    // value={subject}
                                                    placeholder="Subject"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Row end--> */}
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <textarea
                                                    className="form-control form-message"
                                                    placeholder="Message"
                                                    rows="6"
                                                    {...register('message')}
                                                    // onChange={(e) => setMessage(e.target.value)}
                                                    // value={message}
                                                    name="message"
                                                    required
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Row end--> */}
                                    <div className="form-group text-right mb-0">
                                        <input
                                            disabled={loading || !formState.isValid ? true : false}
                                            className="button btn btn-primary"
                                            type="submit"
                                            value="Send Message"
                                        />
                                    </div>
                                </form>
                            </div>
                            {/* <!-- Quote form end--> */}
                        </div>
                        {/* <!-- Col end--> */}
                        <div className="col-lg-6 align-self-center">
                            <div
                                className="owl-carousel owl-theme testimonial-slide owl-dark"
                                id="testimonial-slide"
                            >
                                <div className="item">
                                    <div className="quote-item quote-square">
                                        <span className="quote-text">
                                            <p align="center">
                                                <strong>QUOTES</strong>
                                            </p>
                                            "There are always higher heights to acheive in our walk
                                            with God. Our walk with God is unending. Never set a
                                            goal which positions you in your comfort zone"
                                        </span>
                                        <div className="quote-item-footer">
                                            <img
                                                className="testimonial-thumb"
                                                src="images/clients/3.jpg"
                                                alt="testimonial"
                                            />
                                            <div className="quote-item-info">
                                                <p className="quote-author">
                                                    Prophet Daniel .O. Badu
                                                </p>
                                                <span className="quote-subtext">
                                                    General Overseer
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Quote item end--> */}
                                </div>
                                {/* <!-- Item 1 end--> */}
                                <div className="item">
                                    <div className="quote-item quote-square">
                                        <span className="quote-text">
                                            <p align="center">
                                                <strong>QUOTES</strong>
                                            </p>
                                            "After the discovery and pursuit of purpose, growth is
                                            the next important thing to consider. Maturity is not
                                            only increase in shape and size, but is a change in our
                                            thinking to align with the fulfilment of that particular
                                            purpose."
                                        </span>
                                        <div className="quote-item-footer">
                                            <img
                                                className="testimonial-thumb"
                                                src="images/clients/3.jpg"
                                                alt="testimonial"
                                            />
                                            <div className="quote-item-info">
                                                <h3 className="quote-author">
                                                    Prophet Daniel .O. Badu
                                                </h3>
                                                <span className="quote-subtext">
                                                    General Overseer
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Quote item end--> */}
                                </div>
                                {/* <!-- Item 2 end--> */}
                                <div className="item">
                                    <div className="quote-item quote-square">
                                        <span className="quote-text">
                                            <p align="center">
                                                <strong>QUOTES</strong>
                                            </p>
                                            "Consistency is a major factor in acheiving spiritual
                                            success. You must be consistent in the pursuit of your
                                            spiritual goals. Even after failures, change your ways ;
                                            better them and keep on in the direction of your vision"
                                        </span>
                                        <div className="quote-item-footer">
                                            <img
                                                className="testimonial-thumb"
                                                src="images/clients/3.jpg"
                                                alt="testimonial"
                                            />
                                            <div className="quote-item-info">
                                                <h3 className="quote-author">
                                                    Prophet Daniel .O. Badu
                                                </h3>
                                                <span className="quote-subtext">
                                                    General Overseer
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Quote item end--> */}
                                </div>
                                {/* <!-- Item 3 end--> */}
                            </div>
                            {/* <!-- Testimonial carousel end--> */}
                        </div>
                        {/* <!-- Col end--> */}
                    </div>
                    {/* <!-- Content row end--> */}
                </div>
                {/* <!-- Container end--> */}
            </section>

        </div>
    )

}
