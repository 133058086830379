// import { checkPropTypes } from 'prop-types';
import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './Banner.scss';


export default class Banner extends Component {

    render() {
        var background = this.props.image;
        var link = this.props.link;
        var style = this.props.style;
        return (
            < Fragment >
                < div style={{ backgroundImage: 'url(' + background + ')', ...style }} className="banner-area" id="banner-area" >
                    < div className="container" >
                        < div className="row justify-content-center" >
                            < div className="col" >
                                {this.props.children}
                                {link && < div className="banner-heading" >
                                    < h1 className="banner-title" >
                                        {link}
                                    </ h1 >

                                    < ol className="breadcrumb" >
                                        <li>
                                            <Link to='/home' > Home </Link>
                                        </li>
                                        <li> {link} </li>
                                    </ ol >
                                </ div >}
                            </ div >
                            {/* <!-- Col end--> */}
                        </ div >
                        {/* <!-- Row end--> */}
                    </ div >
                    {/* <!-- Container end--> */}
                </ div >
            </ Fragment >
        )
    }
}

Banner.propTypes = {
    background: PropTypes.string,
    link: PropTypes.string,
}