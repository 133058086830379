import React, { Component } from 'react'
// import christmas from "../../../assets/images/events/christmasstory-1.jpg";
import { Link } from 'react-router-dom';
import christmas from '../../../assets/images/events/joseph.jpeg';

export default class News extends Component {
    render() {
        return (
            <div  >
                <section className="news" id="news">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-md-12">
                                <h2 className="section-title">
                                    <span></span>Our Latest News
                                </h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="latest-post post-large">
                                    <div className="latest-post-media">
                                        <Link className="latest-post-img" to="/events">
                                            <img className="img-fluid" src={christmas} alt="Christmas Story" />
                                        </Link>
                                        <Link className="post-cat" to="/events">
                                            Up next
                                        </Link>
                                        <div className="post-body">
                                            <span className="post-item-date">January ' 27,28,29 '</span>
                                            <h4 className="post-title">
                                                <Link to="/events">The Blessings of Joseph</Link>
                                            </h4>
                                            <div className="post-text">
                                                <p>
                                                    {/* Join us for and AMAZING 3 days program this december */}
                                                </p>
                                                {/* <!--<div className="text-right"><a href="#">Read More <i className="fa fa-long-arrow-right"> </i></a></div>--> */}
                                            </div>
                                            {/* <!--<a className="btn btn-primary" href="#">Read More</a>--> */}
                                        </div>
                                        {/* <!-- Post body end--> */}
                                    </div>
                                    {/* <!-- Post media end--> */}
                                </div>
                                {/* <!-- Latest post end--> */}
                            </div>
                            {/* <!-- Col big news end--> */}
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="latest-post">
                                            <div className="post-body">
                                                <Link className="post-cat" to="/events">
                                                    Past Event
                                                </Link>
                                                <h4 className="post-title">
                                                    <Link to='/events'>#Amazing</Link>
                                                </h4>
                                                <span className="post-item-date">---</span>
                                                <div className="post-text">
                                                    <p>
                                                        
                                                    </p>
                                                    {/* <!--<div className="text-right"><a href="#">Read More <i className="fa fa-long-arrow-right"> </i></a></div>--> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- Latest post end--> */}
                                    </div>
                                    {/* <!-- Col end--> */}
                                    <div className="col-lg-6">
                                        <div className="latest-post">
                                            <div className="post-body">
                                                <a className="post-cat" href="/events">
                                                    Past Event
                                                </a>
                                                <h4 className="post-title">
                                                    <Link to='/events'>#The Ultimate Sacrifice</Link>
                                                </h4>
                                                <span className="post-item-date">---</span>
                                                <div className="post-text">
                                                    <p>
                                                        An Educative and Spiritual drama to make your life a
                                                        blessing,with side attractions from our choreography
                                                        team.
                                                    </p>
                                                    {/* <!--<div className="text-right"><a href="#">Read More <i className="fa fa-long-arrow-right"> </i></a></div>--> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- Latest post end--> */}
                                    </div>
                                    {/* <!-- Col end--> */}
                                </div>
                                {/* <!-- row end--> */}
                            </div>
                            {/* <!-- Col small news end--> */}
                        </div>
                        {/* <!-- Content row end--> */}
                    </div>
                    {/* <!-- Container end--> */}
                </section>
            </div>
        )
    }
}
