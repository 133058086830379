import React, { useState } from "react";
import { Link } from 'react-router-dom';
import logo from "../assets/images/logo/logo-white-rect.png";
import { useForm } from "react-hook-form";
import { Patterns } from "../utils/patterns.js";
import { toast } from "react-toastify";
import { firestore } from "../config";

export default function Footer() {

  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, } = useForm();

  function subscribeToNewsLetter(event) {
    try {
      setLoading(false);

      toast.info('Subscribing to news letter');
      firestore.collection('news-letter').add({
        email: event.email,
      }).then(v => {
        toast.success('Thank for subscribing to put newsletter');
        setLoading(false)
      }).catch(error => {
        setLoading(false)
        toast.error(error?.message ?? "Something bad happened, Please try again");
      })
    } catch (error) {

    }
  }
  return (
    <section style={{ padding: 0, marginTop: 100 }} >
      <div className="footer" id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="footer-top-bg row">
              <div className="col-lg-4 footer-box">
                <i className="icon icon-map-marker2"></i>
                <div className="footer-box-content">
                  <h3>Head Office</h3>
                  <p>Nana Yaa Dei Plaza , Dome St John's, Accra</p>
                </div>
              </div>
              {/* <!-- Box 1 end--> */}
              <div className="col-lg-4 footer-box">
                <i className="icon icon-phone3"></i>
                <div className="footer-box-content">
                  <h3>Call Us</h3>
                  <p>
                    <a href="tel:+233542099707">(+233) 542-099-707</a>
                  </p>
                </div>
              </div>
              {/* <!-- Box 2 end--> */}
              <div className="col-lg-4 footer-box">
                <i className="icon icon-envelope"></i>
                <div className="footer-box-content">
                  <h3>Mail Us</h3>
                  <p>
                    <a href="mailto:godlifeencounterchurch18@gmail.com">
                      godlifeencounterchurch18@gmail.com
                    </a>
                  </p>
                </div>
              </div>
              {/* <!-- Box 3 end--> */}
            </div>
            {/* <!-- Content row end--> */}
          </div>
          {/* <!-- Container end--> */}
        </div>
        {/* <!-- Footer top end--> */}
        <div className="footer-main bg-overlay">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12 footer-widget footer-about">
                <div className="footer-logo">

                  <Link to='/home'><img src={logo} alt="" width="auto" height="50" /></Link>

                </div>
                <p>
                  "Has a wonderful focus,its directed at you. Aimed at
                  empowering you to live an impactful life".
                </p>
                <div className="footer-social">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/Godlife-Encounter-Church-227311804465008/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/godlifeencounter/?hl=en"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li> <li>
                      <a
                        href="https://https://play.google.com/store/apps/details?id=inc.loop.gec"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-android"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://apps.apple.com/us/app/my-gec/id1582860124"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-apple"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <!-- Footer social end--> */}
              </div>
              {/* <!-- About us end--> */}
              <div className="col-lg-4 col-md-12 footer-widget">
                <h3 className="widget-title">Useful Links</h3>
                <ul className="list-dash">
                  <li>
                    <Link to="/partner-us">Give</Link>
                  </li>
                  <li>
                    <Link to="/events">Events</Link>
                  </li>
                  <li>
                    <Link to="/characters">Bible Characters</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-12W footer-widget">
                <h3 className="widget-title">Subscribe</h3>
                <div className="newsletter-introtext">
                  Do subscribe to our news feeds. Kindly send us your
                  email.
                </div>
                <form
                  className="newsletter-form"
                  id="newsletter-form"
                  onSubmit={handleSubmit(subscribeToNewsLetter)}
                >
                  <div className="form-group">
                    <input
                      className="form-control form-control-lg"
                      id="newsletter-form-email"
                      type="email"
                      name="email"
                      placeholder="Email Address"

                      {...register('email', { required: true, validate: 'Please enter correct email', pattern: Patterns.email, })}
                    />
                    <button disabled={loading} type="submit" className="btn btn-primary">
                      <i className="fa fa-paper-plane"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- Content row end--> */}
          </div>
          {/* <!-- Container end--> */}
        </div>
        {/* <!-- Footer Main--> */}
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="copyright-info">
                  <span>
                    Copyright © {new Date().getFullYear()} GEC. All Rights Reserved.
                  </span>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="footer-menu">
                  <ul className="nav unstyled">
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- Row end--> */}
          </div>
          {/* <!-- Container end--> */}
        </div>
        {/* <!-- Copyright end--> */}
      </div>
    </section>
  );

}
