import React, { Fragment, useState } from "react";
import PayCard from "../../assets/images/news/pay-card.png";
import MomoCard from "../../assets/images/news/pay-momo.png";
import "./Donation.scss";
// import logo from "../../assets/images/logo/logo-white-sqr.png"; 
import { toast } from "react-toastify";
import { usePaystackPayment } from "react-paystack";

// import { PaystackProps } from "react-paystack/dist/types";

export default function Donation() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState(undefined);

  const config = {
    publicKey: "pk_live_a9908cd31c4d2c46ea356b83342b5f95bb6d8595",
    reference: `${Date.now()}`,
    amount: amount * 100.00,
    currency: "GHS",
    email: email ?? "paulboamah22@gmail.com",
    channels: ["bank", "card", "mobile_money", "qr", "ussd"],
    firstname: name.split(" ")[0] ?? "",
    label: "GEC",
    phone: phone,
    lastname: name.split(" ")[1] ?? "",

    // customizations: {
    //   title: "My GEC",
    //   description: "Support the word of God",
    //   logo: "www.godlifeencounterchurch.org/static/media/logo-vertical.aa6334ad.png",
    // },
  };

  const handleFlutterPayment = usePaystackPayment(config);

  const [response, setResponse] = useState("");

  const showToast = () =>
    toast.info(response, {
      position: "bottom-left",

      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const pay = () => {
    handleFlutterPayment(
      /// on success
      (response) => {
        setResponse(response.status);
      },
      /// on error
      () => {
        showToast();
      }
    );
    // gateway()
  };

  return (
    <Fragment>
      <div className=" donation banner-area" id="banner-area">
        <section className="main-container" id="main-container">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12"></div>
            </div>
            {/* <!-- Title row end--> */}

            {/* <!-- Row End--> */}
          </div>
          {/* <!-- container end--> */}
          <div className="gap-60"></div>
          <div className="gap-60"></div>
          <div className="ts-form" id="ts-form">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <form
                    className="contact-form"
                    id="contact-form"
                    method="POST"
                    onSubmit={(e) => {
                      e.preventDefault();
                      pay();
                    }}
                  >
                    <div className="error-container"></div>
                    <div className="row">
                      <div className="col-lg-8">
                        <div className="form-group">
                          <input
                            className="form-control form-name"
                            id="name"
                            name="name"
                            placeholder="Full Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            required
                          />
                        </div>
                      </div>
                      {/* <!-- Col end--> */}
                      <div className="col-lg-8">
                        <div className="form-group">
                          <input
                            className="form-control form-email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="form-group">
                          <input
                            className="form-control form-phone"
                            id="phone"
                            name="phone"
                            placeholder="Phone number"
                            type="tel"
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-lg-8">
                        <div className="form-group">
                          <input
                            className="form-control form-email"
                            id="amount."
                            name="amount"
                            placeholder="Enter Amount"
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(e.target.valueAsNumber)}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <button
                            className="submit-button btn btn-primary"
                            name="pay"
                            type="submit"
                          >
                            SUBMIT
                          </button>
                        </div>
                      </div>
                      <p className="lead">OR</p>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <a
                            className="submit-button btn btn-primary"
                            rel="noreferrer"
                            target="_blank"
                            href="https://paystack.com/pay/gec"
                          >
                            PORTAL
                          </a>
                        </div>
                      </div>
                      {/* <!-- Col 12 end--> */}
                    </div>
                    {/* <!-- Form row end--> */}
                  </form>
                  {/* <!-- Form end--> */}
                </div>
                <div className="col-lg-6">
                  <p className="heading section-title">Online Donation</p>
                  <p>
                    This is a platform you can use to make Offering, Tithe,
                    Pledge and other payments online. Our platform accept Card
                    payments such as VISA {"&"} Master and Mobile Money ( Momo
                    Number 0242311154 )
                  </p>
                  <div className="row">
                    <img
                      src={PayCard}
                      alt="Paycard"
                      className="card"
                      height="15px"
                    />
                    <img
                      src={MomoCard}
                      alt="momo-card"
                      className="card"
                      height="15px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
}
