import React, { Component, Fragment } from 'react'
import image from '../../assets/images/banner/arc.jpg';
import Sidebar from '../Utils/Sidebar/Sidebar';
import './Branches.scss';


export default class Branches extends Component {
    render() {
        return (
            <Fragment>

                {/* <!-- Banner area end--> */}
                <section className="main-container" id="main-container">

                    <div id="ts-gallery-layout" className="ts-gallery-layout">
                        <div className="container">
                            <div className="row text-left">
                                <div className="col-lg-12">
                                    <h2 className="">
                                        <span className="section-title-tagline title-light"></span>
                                    </h2>
                                </div>
                            </div>
                            {/* <!-- Row End--> */}
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="carrer-gallery-layout">

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Container End --> */}
                    </div>
                    {/* <!-- Gallery End --> */}
                </section>

                <section id="career-body" className="career-body branches">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="ts-text-block mrt-60">
                                    <img src={image} width="100%" alt='branches' height="20%" />
                                    <div className="career-body-inner">
                                        <div className="job-post-box">
                                            <div className="ts-text-block mrt-20">
                                                <div className="heading">
                                                    <h2 className=" content-title border-none"><span className="shortheading"></span> Our Branches</h2>
                                                </div>
                                            </div>
                                            <div className="ts-text-block mrt-20">
                                                <div className='item' ><strong>I. Godlife Encounter Church ( Headquarters - Accra )</strong><br />
                                                    <ul>
                                                        <li> Sunday Service 8:00am - 11:00pm</li>
                                                        {/* <li> Tuesday Service 6:30pm - 7:30pm</li> */}
                                                        <li> Thursday Service 6:30pm - 8:30pm</li>
                                                        {/* <li> Watch-Night Service ( last friday of every month ) 10:00pm -4:30am</li> */}
                                                        <li> Venue: @ Nana Yaa Dei Plaza , 340 St Johns - Dome - Kwabenya - Brekusu Rd, Accra</li>
                                                        <li> Tel : 0542099707 / 0247084082</li>
                                                    </ul>
                                                </div>

                                                <div className='item'><strong>II. Godlife Encounter Church (East Legon) </strong><br />
                                                    <ul>
                                                        <li> Sunday Service 10:30am - 12:00pm</li>
                                                        <li> Venue: @ Polynter House Opp. Koffee Lounge (American House) , Accra</li>
                                                        <li> Tel : 0542099707 / 0247084082</li>
                                                    </ul>
                                                </div>

                                                <div className='item'><strong>III. Godlife Encounter Church ( Kumasi )</strong><br />
                                                    <ul>
                                                        <li> Sunday Service 8:30am - 11:30am</li>
                                                        <li> Supernatural Thursday Service 6:30pm - 9:00pm (Katanga B'ball Court)</li>
                                                        <li> Venue: @ Baby Brunei, third floor, KNUST Campus, Accra-Kumasi Road</li>
                                                        <li> Tel : 0548655299 / 0548018354</li>
                                                    </ul>
                                                </div>

                                                <div className='item'><strong>IV. Godlife Encounter Church ( Cape Coast )</strong><br />
                                                    <ul>
                                                        <li> Sunday General service ( SRC HALL CONFERENCE ROOM @ 9am-11.30am)</li>
                                                        <li> Wednesday Prayer service for Diaspora ( 7-8.30pm @Amamoma field)</li>
                                                        <li> Friday Prayer Service for SRC and it’s environs (7-8.30pm @ SRC car park)</li>
                                                        <li> Tel : 0240654268 / 0242286325</li>
                                                    </ul>




                                                </div>


                                            </div>

                                            {/* <!-- Job Box End --> */}

                                            {/* <!-- Row End --> */}
                                        </div>
                                        {/* <!-- Job Post Box End --> */}

                                        {/* <!-- Job Post Box End --> */}
                                    </div>
                                    {/* <!-- Career Body Inner End --> */}
                                </div>
                            </div>
                            {/* <!-- Col End --> */}
                            <Sidebar />
                            {/* <!-- Col End --> */}
                        </div>

                        {/* <!-- Row End --> */}
                    </div>
                    {/* <!-- Container End --> */}
                </section>
                {/* <div className="gap-10"></div> */}
                {/* <div className="ts-text-block mrt-60">

                </div> */}
            </Fragment>
        )
    }
}
//<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.6662496779427!2d-1.5862027858385477!3d6.6881984230837555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdb95acfd3ae491%3A0xff9116d70e93e707!2sOjampa!5e0!3m2!1sen!2sgh!4v1608619094570!5m2!1sen!2sgh" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" ariaHidden="false" tabindex="0"></iframe>