
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import '../../scss/style.scss';
import dataCatalog from './data';


export default function Catalog() {
    return (
        <Fragment>
            <div className='catalog'>
                <div style={{ marginBottom: '10px' }} className='title'>
                    <h4 className='catalog-title'>Catalog</h4>
                </div>
                <div className='catalog-list'>

                    {
                        dataCatalog.splice(1).map(x => <CatalogItem key={x.title} {...x} />)
                    }

                </div>
                <div className='disclaimer'>
                    <p>Previous versions were only available in hard copy, <Link to='/contact-us'>Contact Us</Link> for a copy </p>
                </div>
            </div>

        </Fragment>
    )
}



function CatalogItem({ cover, month, link }) {
    return (
        <div className='catalog-item' >
            <img className='img-auto' src={cover} alt={month} />
            <div className='bar'>
                <p>{month}</p>
                <a className='btn' href={link} download target={'_blank'} rel="noreferrer"  > <i className='fa fa-download'></i> </a>
            </div>
            <div>

            </div>
        </div>
    )
}
