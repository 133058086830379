import React, { Component, Fragment } from 'react';
import image from '../../assets/images/banner/prophet-no-bg.png';
import Sidebar from '../Utils/Sidebar/Sidebar';
// import banner from '../../assets/images/banner/APP.jpg';
// import Banner from '../Utils/Banner/Banner';
import './Prophet.scss';

export default class AboutProphet extends Component {

    render() {
        return (
            <Fragment>

                <section className="main-container section-padding2" id="main-container">
                    {/* <Banner image={banner} link='About Prophet' /> */}

                </section>

                <section className="bg-overlay ts-chart1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="ts-text-block mrt-60">
                                    <div className="career-body-inner">

                                        <img src={image} className='main-image' alt='Prophet Daniel' />
                                        <div className="job-post-box bg-white">

                                            <div className="container">
                                                &nbsp;
                                                <h2 className="one">Prophet Daniel</h2>
                                                <div className="ts-text-block mrt-35">
                                                    <p className="al">Prophet Daniel O. Badu is the Founder and Head Pastor of
                                                        Godlife Encounter Church (GEC). Prophet Daniel effectively communicates the
                                                        Word of God with a unique blend of humor, practical examples and a dynamic
                                                        teaching style aimed at empowering all to live impactful lives.


                                                    </p>

                                                    <p className="al">He strongly demonstrates the Prophetic Grace with many signs,
                                                        wonders and other supernatural experiences.
                                                        He is the author of Meadows of Life devotional, which has been specially
                                                        designed to enhance your daily walk with the Spirit of God. It contains
                                                        timeless and practical truths that will help you experience a very fruitful
                                                        life as a child of God.

                                                    </p>
                                                    <p>. </p>
                                                </div>


                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                            <Sidebar />
                        </div>


                    </div>


                </section>


            </Fragment>

        )
    }
}