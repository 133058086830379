import React from "react";
import logo from "../../assets/images/logo/logo-vertical.png";
import { Link } from 'react-router-dom';

class AppBar extends React.Component {


  render() {
    return (
      <div className='app-bar'>
        <div className="site-top">
          <div className="topbar-transparent" id="top-bar">
            <div className="container">
              <div className="row">
                <div className="col-lg-9 col-md-9 col-sm-12">
                  <ul className="top-info">
                    <li>
                      <span className="info-icon"></span>
                      <div className="info-wrapper">
                        <p className="info-title"> </p>
                      </div>
                    </li>
                    <li>
                      <span className="info-icon"></span>
                      <div className="info-wrapper">
                        <p className="info-title"></p>
                      </div>
                    </li>
                    {/* <!--<li className="last"><span className="info-icon"><i className="icon icon-map-marker2"></i></span>
                           <div className="info-wrapper">
                              <p className="info-title">Opposite Mtn Office, East Legon</p>
                           </div>
                        </li>--> */}
                  </ul>
                  {/* <!-- Ul end--> */}
                </div>
                {/* <!--Top info end--> */}
                <div className="col-lg-3 col-md-3 col-sm-12 text-lg-right text-md-center">
                  <ul className="top-social">
                    <li>
                      <a
                        title="Facebook"
                        href="https://www.facebook.com/Godlife-Encounter-Church-227311804465008/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="social-icon">
                          <i className="fa fa-facebook"></i>
                        </span>
                      </a>
                      <a
                        title="Instagram"
                        href="https://www.instagram.com/godlifeencounter/?hl=en"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="social-icon">
                          <i className="fa fa-instagram"></i>
                        </span>
                      </a>
                      <a
                        title="Play Store"
                        href="https://https://play.google.com/store/apps/details?id=inc.loop.gec"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="social-icon">
                          <i className="fa fa-android"></i>
                        </span>
                      </a>

                      <a
                        title="app Store"
                        href="https://apps.apple.com/us/app/my-gec/id1582860124"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="social-icon">
                          <i className="fa fa-apple"></i>
                        </span>
                      </a>
                    </li>
                    {/* <!-- List End --> */}
                  </ul>
                  {/* <!-- Top Social End --> */}
                </div>
                {/* <!--Col end--> */}
              </div>
              {/* <!--Content row end--> */}
            </div>
            {/* <!--Container end--> */}
          </div>
          {/* <!--Top bar end--> */}

          <header className="header-trans-leftbox clearfix" id="header">
            <div className="container">
              <div className="header-wrapper clearfix">
                <div className="site-nav-inner">
                  <nav className="navbar navbar-expand-lg">
                    <div className="navbar-brand navbar-header">
                      <div className="logo">
                        <Link to="/home">
                          {/* fix image alt */}
                          <img src={logo} className='appLogo' style={{ height: '60px' }} width='auto' alt="chruch logo" />
                        </Link>
                      </div>
                      {/* <!-- logo end--> */}
                    </div>
                    {/* <!-- Navbar brand end--> */}
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon">
                        <i className="icon icon-menu"></i>
                      </span>
                    </button>
                    {/* <!-- End of Navbar toggler--> */}
                    <div
                      className="collapse navbar-collapse justify-content-end"
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                          <Link className="nav-link" to="/home">
                            Home
                          </Link>
                        </li>

                        <li className="nav-item dropdown">

                          <Link to="/events">Events</Link>

                        </li>


                        <li className="nav-item dropdown">
                          {/* fix link */}
                          <a className="nav-link" href="/" data-toggle="dropdown">
                            RESOURCES<i className="fa fa-angle-down"></i>
                          </a>
                          <ul className="dropdown-menu" role="menu">
                            <li>
                              <Link to="/devotional">Devotional</Link>
                            </li><li>
                              <Link to="/podcast">Podcast</Link>
                            </li>
                            <li>
                              <Link to="/materials">Growth Materials</Link>
                            </li>

                            {/* <li>
                              <Link to="/testimonial">Testimonies</Link>
                            </li> */}
                            <li>
                              <Link to="/characters">
                                Bible Characters
                              </Link>
                            </li>
                          </ul>
                        </li>
                        {/* <!-- li end--> */}

                        {/* <!-- li end--> */}
                        {/* <li className="nav-item dropdown">

                        </li> */}
                        {/* <!-- li end--> */}
                        <li className="nav-item dropdown">
                          {/* fix link */}
                          <a
                            className="nav-link"
                            href="/home"
                            data-toggle="dropdown"
                          >
                            ABOUT<i className="fa fa-angle-down"></i>
                          </a>
                          <ul className="dropdown-menu" role="menu">
                            <li>
                              <Link to="/about-prophet">About Prophet Daniel</Link>
                            </li>
                            <li>
                              <Link to="/our-vision">Vision</Link>
                            </li>
                            <li>
                              <Link to="/our-faith">Our Statement Of Faith</Link>
                            </li>
                            <li>
                              <Link to="/branches">Our Branches</Link>
                            </li>
                          </ul>
                        </li>

                        <li className="nav-item dropdown">
                          <Link className="nav-link" to="/contact-us">
                            CONTACT
                          </Link>
                        </li>

                        <li className="nav-item dropdown">
                          <Link className="nav-link" to="/partner-us">
                            PARTNER WITH US
                          </Link>
                        </li>
                      </ul>
                      {/* <!--Nav ul end--> */}
                    </div>
                  </nav>
                  {/* <!--Collapse end--> */}
                  {/* <div className="nav-search">
                  <span id="search">
                    <i className="icon icon-search"></i>
                  </span>
                </div> */}
                  {/* <!-- Search end--> */}
                  {/* implement hiddend attr */}
                  {/* <div className="search-block" hidden>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search"
                  />
                  <span className="search-close">×</span>
                </div> */}
                  {/* <!-- Site search end--> */}
                </div>
                {/* <!--Site nav inner end--> */}
              </div>
              {/* <!-- Header wrapper end--> */}
            </div>
            {/* <!--Container end--> */}
          </header>
          {/* <!--Header end--> */}
        </div>
      </div>
    );
  }
}

export default AppBar;
