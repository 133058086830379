import React, { Component } from "react";
import "../../scss/style.scss";
import "./characters.scss";
import Joshua from "../../assets/images/case-study/4.jpg";
import Paul from "../../assets/images/case-study/1.jpg";
import Deborah from "../../assets/images/case-study/2.jpg";
import Gideon from "../../assets/images/case-study/6.jpg";
import Abigail from "../../assets/images/case-study/3.jpg";
import Moses from "../../assets/images/case-study/7.jpg";
import Banner from "../Utils/Banner/Banner";
import image from '../../assets/images/banner/characters.jpg';
export default class Characters extends Component {
  render() {
    return (
      <React.Fragment>
        <Banner image={image} link='Bible Characters' />

        <section className="main-container no-padding" id="main-container">
          <div className="ts-services ts-service-pattern" id="ts-services">
            <div className="container">

              {/* <!-- Title row end--> */}
              <div className="row">
                <div className="col-lg-4 col-md-12">
                  <div className="ts-service-box">
                    <div className="ts-service-image-wrapper">
                      <img alt='paula' className="img-fluid" src={Paul} />
                    </div>
                    <div className="ts-service-content">
                      {/* <span className="ts-service-icon"> </span> */}
                      <h3 className="service-title" align="center">
                        PAUL
                      </h3>
                      {/* <!--<p>The Church is Christ centered and focused on Christ. It is directed at you. Aimed at empowering you to live an impactful life.</p>--> */}
                      {/* <!--<p><a className="link-more" href="#">Read More <i className="icon icon-right-arrow2"></i></a></p>--> */}
                    </div>
                  </div>
                  {/* <!-- Service1 end--> */}
                </div>
                {/* <!-- Col 1 end--> */}
                <div className="col-lg-4 col-md-12">
                  <div className="ts-service-box">
                    <div className="ts-service-image-wrapper">
                      <img
                        className="img-fluid"
                        src={Deborah}
                        alt='deborah'

                      />
                    </div>
                    <div className="ts-service-content">
                      {/* <span className="ts-service-icon"> </span> */}
                      <h3 className="service-title" align="center">
                        DEBORAH
                      </h3>
                      {/* <!--<p>The Church is Christ centered and focused on Christ. It is directed at you. Aimed at empowering you to live an impactful life.</p>--> */}
                      {/* <!--<p><a className="link-more" href="#">Read More <i className="icon icon-right-arrow2"></i></a></p>--> */}
                    </div>
                  </div>
                  {/* <!-- Service2 end--> */}
                </div>
                {/* <!-- Col 2 end--> */}
                <div className="col-lg-4 col-md-12">
                  <div className="ts-service-box">
                    <div className="ts-service-image-wrapper">
                      <img className="img-fluid" src={Gideon} alt='gideon' />
                    </div>
                    <div className="ts-service-content">
                      {/* <span className="ts-service-icon"> </span> */}
                      <h3 className="service-title" align="center">
                        GIDEON
                      </h3>
                      {/* <!--<p>The Church is Christ centered and focused on Christ. It is directed at you. Aimed at empowering you to live an impactful life.</p>--> */}
                      {/* <!--<p><a className="link-more" href="#">Read More <i className="icon icon-right-arrow2"></i></a></p>--> */}
                    </div>
                  </div>
                  {/* <!-- Service3 end--> */}
                </div>
                {/* <!-- Col 3 end--> */}
              </div>
              {/* <!-- Content 1 row end--> */}
              <div className="gap-60"></div>
              <div className="row">
                <div className="col-lg-4 col-md-12">
                  <div className="ts-service-box">
                    <div className="ts-service-image-wrapper">
                      <img className="img-fluid" src={Joshua} alt="joshua" />
                    </div>
                    <div className="ts-service-content">
                      {/* <span className="ts-service-icon"> </span> */}
                      <h3 className="service-title" align="center">
                        JOSHUA
                      </h3>
                      {/* <!--<p>The Church is Christ centered and focused on Christ. It is directed at you. Aimed at empowering you to live an impactful life.</p>--> */}
                      {/* <!--<p><a className="link-more" href="#">Read More <i className="icon icon-right-arrow2"></i></a></p>--> */}
                    </div>
                  </div>
                  {/* <!-- Service4 end--> */}
                </div>
                {/* <!-- Col 4 end--> */}
                <div className="col-lg-4 col-md-12">
                  <div className="ts-service-box">
                    <div className="ts-service-image-wrapper">
                      <img alt='abigail' className="img-fluid" src={Abigail} />
                    </div>
                    <div className="ts-service-content">
                      {/* <span className="ts-service-icon"> </span> */}
                      <h3 className="service-title" align="center">
                        ABIGAIL
                      </h3>
                      {/* <!--<p>The Church is Christ centered and focused on Christ. It is directed at you. Aimed at empowering you to live an impactful life.</p>--> */}
                      {/* <!--<p><a className="link-more" href="#">Read More <i className="icon icon-right-arrow2"></i></a></p>--> */}
                    </div>
                  </div>
                  {/* <!-- Service5 end--> */}
                </div>
                {/* <!-- Col 5 end--> */}
                <div className="col-lg-4 col-md-12">
                  <div className="ts-service-box">
                    <div className="ts-service-image-wrapper">
                      <img alt='mosses' className="img-fluid" src={Moses} />
                    </div>
                    <div className="ts-service-content">
                      {/* <span className="ts-service-icon"> </span> */}
                      <h3 className="service-title" align="center">
                        MOSES
                      </h3>
                      {/* <!--<p>The Church is Christ centered and focused on Christ. It is directed at you. Aimed at empowering you to live an impactful life.</p>--> */}
                      {/* <!--<p><a className="link-more" href="#">Read More <i className="icon icon-right-arrow2"></i></a></p>--> */}
                    </div>
                  </div>
                  {/* <!-- Service6 end--> */}
                </div>
                {/* <!-- Col 6 end--> */}
              </div>
              {/* <!-- Content Row 2 end--> */}
            </div>
            {/* <!-- Container end--> */}
          </div>
        </section>

      </React.Fragment>
    );
  }
}
