import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import background from "../../assets/images/banner/devotional.jpg";
import Banner from '../Utils/Banner/Banner';
import BlogTile from '../Utils/BlogTile/BlogTile';
import Catalog from './Catalog';
import './Devotional.scss';
import dataCatalog from './data';

export default class Devotional extends Component {


    devotionalLink = ""
    title = ''
    month = '';
    fileSize = '';
    cover;
    highlights = [];

    constructor() {
        super();
        this.devotionalLink = dataCatalog[0].link;
        this.title = dataCatalog[0].title;
        this.month = dataCatalog[0].month;
        this.fileSize = dataCatalog[0].fileSize;
        this.cover = dataCatalog[0].cover;
        this.highlights = dataCatalog[0].highlights ?? []
    }

    render() {
        return (
            <Fragment>
                <Banner image={background} link='Devotional' />
                <section className='devotional main-container' id='main-container' >

                    <div className="container">
                        <BlogTile image={this.cover} title={this.title}
                            details='This devotional has been specially designed to enhance your daily walk with the Spirit of God. It contains timeless and practical truths that will help you experience a very fruitful life as a child of God. In each edition, you will find answers to the following and more:' >
                            <ul>
                                {
                                    this.highlights.map(v => <li>{v}</li>)
                                }
                            </ul>

                            <p style={{ marginTop: 15 }}>{this.month} version is available for download ({this.fileSize})</p>

                            <Link className='btn' to='/contact-us' >Get a hard copy</Link>
                            <a className='btn' href={this.devotionalLink} target="_blank" download={this.devotionalLink} rel="noopener noreferrer"> <i className='fa fa-download'></i> Download PDF</a>
                        </BlogTile>

                        {/* <DevotionalVideos /> */}

                        <Catalog />

                    </div>
                </section>
            </Fragment>
        )
    }
}




