import React, { Component } from 'react'
import Banner from '../Utils/Banner/Banner'
import './materials.scss';
import image from '../../assets/images/banner/materials1.jpg';
import appBanner from '../../assets/images/materials/app-banner.jpg';
import devotional from '../../assets/images/materials/devotional.jpg';
import podcast from '../../assets/images/materials/podcast.jpg';
import BlogTile from '../Utils/BlogTile/BlogTile';
import { Link } from 'react-router-dom';

class Materials extends Component {
   content = [
      {
         key: 1,
         image: devotional,
         title: 'Devotional',
         details: 'Begin your day with easy to read and easy to understand daily devotional. The devotionals are specially designed to help you enjoy life in Christ and live a victorious life on a daily basis.',
         to: [
            <Link className='btn' to='/devotional' >Read More </Link>
         ]

      }, {
         key: 2,
         image: podcast,
         title: 'Podcast',
         details: 'Enjoy practical and in-depth sermons/messages from the man of God - Prophet Daniel. These sermons will help get the sails of life  in the right direction.',
         to: [
            <Link className='btn' to='/podcast' >Read More </Link>
         ],
      }, {
         key: 3,
         image: appBanner,
         title: 'My GEC App',
         details: 'The "My GEC app" gives you access to our library of podcasts, inspirational messages, quotes from the Prophet and other life changing tools to boost your walk with Christ. The app is available on the platforms below, select your target platform to proceed.',
         to: [
            <a className='btn download' href='https://https://play.google.com/store/apps/details?id=inc.loop.gec' target='_blank' rel='noreferrer' ><i className="fa fa-android"></i> Download</a>,
            <a className='btn download' href='https://apps.apple.com/us/app/my-gec/id1582860124' target='_blank' rel='noreferrer' > <i className='fa fa-apple'></i> Download</a>,
            <a className='btn download' href='https://app.godlifeencounterchurch.org' target='_blank' rel='noreferrer' > <i className='fa fa-globe'></i> Web</a>,
         ],
      },
   ]
   render() {
      return <div className='materials'>

         <Banner link='Growth Materials' image={image} />

         {/* <!-- Banner area end--> */}
         <section className="materials main-container " id="main-container">
            <div className="container">
               {this.content.map(item => <BlogTile key={item.key} {...item} >

                  {item.to}
               </BlogTile>)}
            </div>
         </section>
         {/* <div className="gap-40"></div> */}
      </div>
   }
}

export default Materials