import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import bannerImage from '../../assets/images/banner/podcasts.jpg'
import podcast from '../../assets/images/materials/app-banner.jpg'
import Banner from '../Utils/Banner/Banner';
import BlogTile from '../Utils/BlogTile/BlogTile'

export default class Podcast extends Component {
    render() {
        return (
            <div className='podcast'>
                <Banner image={bannerImage} link='Podcast' />
                <section className="main-container" id='main-container' >
                    <div className="container">
                        <BlogTile image={podcast} title='Podcasts on My GEC app' details='Download the "My GEC" app from playstore, appstore or use the the web version to access the life transforming messages from our man of God- Prophet Daniel'>
                            <Link to="/materials#3" className="btn">More Info</Link>
                        </BlogTile>
                    </div>
                </section>
            </div>
        )
    }
}
