import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/performance'

let firebaseConfig = {
    apiKey: "AIzaSyD0ti5ylhnSZXEB7ghE6CYSVhoOtRxSuxM",
    authDomain: "gec-podcast.firebaseapp.com",
    databaseURL: "https://gec-podcast.firebaseio.com",
    projectId: "gec-podcast",
    storageBucket: "gec-podcast.appspot.com",
    messagingSenderId: "67001564831",
    appId: "1:67001564831:web:5a32dcc143ba8ae8f32caa",
    measurementId: "G-8HHLT26RE3"
};


// let 

/// initialize firebase 
firebase.initializeApp(firebaseConfig);
/// init analytice
const analytics = firebase.analytics();
const firestore = firebase.firestore();
const performance = firebase.performance();
const timestamp = firebase.firestore.Timestamp;



export {
    firebaseConfig, analytics, firestore, performance, timestamp
}