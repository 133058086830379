//@ts-check
// import logo from "./logo.svg";
import React, { Fragment } from "react";
import { BrowserRouter, Route, Routes, } from "react-router-dom";
import AppBar from "./components/AppBar/AppBar";
import Footer from "./components/Footer";
import Home from "./components/Home/Home";
import Donation from "./components/Partner/Donation";
import Contact from "./components/Contact/Contact";
import Characters from "./components/Characters/characters";
import Materials from "./components/Materials/materials"
import Eventx from './components/Events/eventx';
import Podcast from "./components/Podcast/Podcast";
import AboutProphet from "./components/Prophet/Prophet";
import OurVision from "./components/Vision/Vision";
import Testimonial from "./components/Testimonial/Testimonial";
import Branches from "./components/Branches/Branches";
import OurFaith from "./components/OurFaith/OurFaith";
import Devotional from "./components/Devotional/Devotional";
import "./App.scss";
import "./components/Footer";
import ScrollToTop from "./components/Utils/SrollToTop/ScrollToTop";
import { ToastContainer } from "react-toastify";
import Boundary from "./components/Error/Boundary";


function App() {
  return (
    <Fragment>
      {/* <Boundary> */}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        pauseOnHover

      />
      <BrowserRouter>
        <ScrollToTop />
        {/* <Boundary > */}
        <div className="App body-inner">
          <AppBar />

          <Routes>
            <Route element={<Contact />} path="/contact-us">

            </Route>
            <Route element={<Donation />} path="/partner-us">

            </Route>
            <Route element={<Materials />} path="/materials">

            </Route>
            <Route element={<Characters />} path="/characters">

            </Route>
            <Route element={<AboutProphet />} path="/about-prophet"  >

            </Route>
            <Route element={<AboutProphet />} path='/about' >

            </Route>
            <Route element={<Eventx />} path="/events">

            </Route>
            <Route element={<Podcast />} path='/podcast' >

            </Route>
            <Route element={<OurVision />} path='/our-vision' >

            </Route>
            <Route element={<Testimonial />} path='/testimonial' >

            </Route>
            <Route element={<Branches />} path='/branches' >

            </Route>
            <Route element={<OurFaith />} path="/our-faith">

            </Route>
            <Route element={<Devotional />} path="/devotional">

            </Route>
            <Route element={<Home />} path="/home">

            </Route>
            <Route element={<Home />} path="/"   >

            </Route>

          </Routes>

          <Footer />

        </div>
        {/* </Boundary> */}
      </BrowserRouter>

    </Fragment>
  );
}
// 
//     
//     
//      
//     
export default App;
