import React from "react";
import Services from './Services/Services'
import Intro from "./Intro/Intro";
import News from "./News/News";
import Quote from "./Quote/Quote";

import "./Home.scss";
import Welcome from "./Welcome/Welcome";
import Hero from "./Hero/Hero";

class Home extends React.Component {


  render() {

    return (
      <div className='home'>
        <Hero />
        <Intro />
        <Services />
        <Welcome />
        {/* <!-- Section chart end--> */}

        <Quote />
        <News />
        {/* <!-- News end--> */}

        {/* <!-- Partners end--> */}

        <div className="ts-text-block mrt-60">&nbsp;</div>
      </div>
    );
  }
}

export default Home;
