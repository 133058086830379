import React, { Component, Fragment } from 'react'
import Banner from '../Utils/Banner/Banner';
import banner from '../../assets/images/banner/a.jpg';
import './Testimonial.scss';

export default class Testimonial extends Component {

    contents = [
        {
            id: 1,
            person: 'Samuel',
            department: 'Accra',
            message: 'Studying and understanding the word of God used to be a problem for me. Now my story has changed by the grace of God through the use of the Meadows of Life Daily Devotional. The devotional has helped me to have a better understanding of the word of God because it contains practical life experiences and detailed explanation of scriptures. Thank God for this amazing gift to the body of Christ. '
        }, {
            id: 2,
            person: 'Evans',
            department: 'Kumasi',
            message: 'The Meadows of Life Daily Devotional has increased my desire to know God because of how the truths of the Bible are presented. It has greatly transformed my daily life as a believer. I am grateful to God and the man of God, Prophet Daniel for this great blessing.'
        },
    ];
    render() {
        return (
            <Fragment>

                <Banner link='Testimonial' image={banner} />
                {/* <!-- Banner area end--> */}
                <section className="testimonial main-container no-padding" id="main-container">


                    <div id="ts-testimonial-static" className="ts-testimonial-static">
                        <div className="container">
                            <div className="row testimonies">

                                {
                                    this.Card({
                                        name: 'ENOCH - Accra',
                                        message: 'I was at crossroads concerning making a decision in business.After encountering the man of God, Prophet Daniel I received counsel and direction on what to do specifically.What followed was an amazing breakthrough to the glory of God.Business has been so good.  Hallelujah!'
                                    })

                                }{
                                    this.Card({
                                        name: 'ENOCH - Accra',
                                        message: 'I was at crossroads concerning making a decision in business.After encountering the man of God, Prophet Daniel I received counsel and direction on what to do specifically.What followed was an amazing breakthrough to the glory of God.Business has been so good.  Hallelujah!'
                                    })

                                }{
                                    this.Card({
                                        name: 'ENOCH - Accra',
                                        message: 'I was at crossroads concerning making a decision in business.After encountering the man of God, Prophet Daniel I received counsel and direction on what to do specifically.What followed was an amazing breakthrough to the glory of God.Business has been so good.  Hallelujah!'
                                    })

                                }{
                                    this.Card({
                                        name: 'ENOCH - Accra',
                                        message: 'I was at crossroads concerning making a decision in business.After encountering the man of God, Prophet Daniel I received counsel and direction on what to do specifically.What followed was an amazing breakthrough to the glory of God.Business has been so good.  Hallelujah!'
                                    })

                                }{
                                    this.Card({
                                        name: 'ENOCH - Accra',
                                        message: 'I was at crossroads concerning making a decision in business.After encountering the man of God, Prophet Daniel I received counsel and direction on what to do specifically.What followed was an amazing breakthrough to the glory of God.Business has been so good.  Hallelujah!'
                                    })

                                }

                            </div>
                        </div>
                        <div className="gap-40"></div>
                        <div className="ts-form" id="ts-form" align="center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h5 className="section-title"><span></span>SHARE WITH US</h5>
                                        <form
                                            className="contact-form"
                                            id="contact-form"
                                            action="contact-form.php"
                                            method="POST"
                                        >
                                            <div className="error-container"></div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control form-name"
                                                            id="name"
                                                            name="name"
                                                            placeholder="Full Name"
                                                            type="text"
                                                            required=""
                                                        />
                                                    </div>
                                                </div>
                                                {/* <!-- Col end--> */}
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control form-number"
                                                            id="url"
                                                            name="url"
                                                            placeholder="Number"
                                                            type="url"
                                                            required=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <input
                                                            className="form-control form-email"
                                                            id="email"
                                                            name="email"
                                                            placeholder="Email"
                                                            type="email"
                                                            required=""
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <textarea
                                                            className="form-control form-message required-field"
                                                            id="message"
                                                            placeholder="Type Testimony"
                                                            rows="8"
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                {/* <!-- Col 12 end--> */}
                                            </div>
                                            {/* <!-- Form row end--> */}
                                            <div className="text-right">
                                                <button className="btn btn-primary tw-mt-30" type="submit">
                                                    Send
                      </button>
                                            </div>
                                        </form>
                                        {/* <!-- Form end--> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="gap-40"></div>
            </Fragment>
        )
    }




    Card(item) {
        return (
            <div className="col-lg-5 col-md-7 col-sm-10">
                <div className="quote-item quote-classic mrb-40">
                    <span className="quote-text faq-quote-text"
                    >
                        {item.message}
                    </span
                    >
                    <div className="quote-item-footer quote-footer-classic">
                        <div className="quote-item-info text-center">
                            <p className="quote-author">{item.name}</p>
                            <span className="quote-subtext">{item.subName}</span>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}
