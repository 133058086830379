
import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "./css/bootstrap.min.css";
import "./css/animate.css";
// import "./css/colorbox.css";
import "./css/font-awesome.min.css";
import "./css/icon-font.css";
import "./css/morris.css";
// import "./css/owl.carousel.min.css";
import "./css/owl.theme.default.min.css";
import './scss/scrollbar.scss';
import "./scss/style.scss";
import "./css/responsive.css";
import "./index.scss";


import App from "./App";

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
